import React from "react";

import Layout from "../components/layout";
import BackgroundImage from "../../static/heartSec.png";
import Media from "react-media";
class ContactUs extends React.Component {
  render() {
    return (
      <Layout backgroundimage={BackgroundImage}>
        <Media query="(min-width: 991px)">
          {matches =>
            matches ? (
              <div className="hero is-fullheight contact">
                <div className="formy">
                  <p className="titleContact">Send Message</p>
                  <form method="POST" action="{ site.contact_url }">
                    <div className="field">
                      <p className="label">Subject</p>
                      <div className="control spof">
                        <input
                          className="input firstInput"
                          type="text"
                          placeholder="I want to order..."
                        ></input>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Email</label>
                      <div className="control spef">
                        <input class="input" type="email" placeholder="Email" />
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Message</label>
                      <div className="control spaf">
                        <textarea
                          class="textarea"
                          placeholder=""
                          rows="10"
                        ></textarea>
                      </div>
                    </div>

                    <div className="field is-grouped">
                      <div class="control spif">
                        <button class="button is-link">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div className="hero is-fullheight">
                <div className="formyy">
                  <p className="titleContacty">Contact Us</p>
                  <div class="fieldd">
                    <label class="labell">Subject</label>
                    <div class="controll">
                      <input
                        class="input"
                        type="text"
                        placeholder="I want to order..."
                      />
                    </div>
                  </div>

                  <div class="fieldd">
                    <label class="labell">Email</label>
                    <div class="controll has-icons-left has-icons-right">
                      <input
                        class="input"
                        type="email"
                        placeholder="Email input"
                        value="hello@"
                      />
                      <span class="icon is-small is-left">
                        <i class="fas fa-envelope"></i>
                      </span>
                      <span class="icon is-small is-right">
                        <i class="fas fa-exclamation-triangle"></i>
                      </span>
                    </div>
                  </div>

                  <div class="fieldd">
                    <label class="labell">Message</label>
                    <div class="controll">
                      <textarea
                        class="textarea"
                        placeholder="Textarea"
                      ></textarea>
                    </div>
                  </div>

                  <div class="field is-grouped">
                    <div class="controll">
                      <button class="button is-link">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </Media>
      </Layout>
    );
  }
}
export default ContactUs;
